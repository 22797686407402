import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import SecurePushImg from "../../assets/images/instant-push/secure-push.webp"
import SecurePushMImg from "../../assets/images/instant-push/secure-push-m.webp"
import OauthImg from "../../assets/images/instant-push/oauth.webp"
import OauthMImg from "../../assets/images/instant-push/oauth-m.webp"
import InstantPushMImg from "../../assets/images/instant-push/instant-push-m.webp"
import DataSafetyMImg from "../../assets/images/instant-push/data-safety-m.webp"
import ArchitectureMImg from "../../assets/images/instant-push/architecture-m.webp"
import BatteryFriendlyMImg from "../../assets/images/instant-push/battery-friendly-m.webp"
import EphemeralProcessingMImg from "../../assets/images/instant-push/ephemeral-processing-m.webp"
const img = "/img/features/BlueMail_Features_Instant_Push_V4.webp"

const InstantPush = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Instant Push | BlueMail App"
        postDescription="BlueMail securely pushes your email notifications so they arrive into your device at instantaneous speeds saving you valuable time."
        postImage="/img/OG/og_image-Instant-Push.png"
        postURL="features-functions/instant-push"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid instant-push w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  <span>Instant Push</span> for Mobile
                </h1>
                <p>
                  BlueMail securely pushes your emails so they arrive into your
                  device at instantaneous speeds saving you valuable time. With
                  BlueMail’s simplicity in configuring your accounts, receiving
                  your emails has never been faster or more intuitive. We
                  believe we have come up with the best possible solution to
                  this challenge both in terms of user experience and data
                  safety on all platforms including on mobile and desktop.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={InstantPushMImg} alt="Instant Push for Mobile" />
            </div>
          </div>
        </div>
        <div className="container-fluid data-safety w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  Data <span>Safety</span>
                </h2>
                <p>
                  At BlueMail, the security and privacy of our users is a top
                  priority. We strongly believe all of our users own their
                  information and we never store emails or sell any user data.
                  Our state-of-the-art technological architecture allows us to
                  deliver safer industry-leading email solutions and calendar
                  services for you and your entire organization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={DataSafetyMImg} alt="Data Safety" />
            </div>
          </div>
        </div>
        <div className="container-fluid push-architecture w-707 r">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  Instant Push <span>Architecture</span>
                </h2>
                <p>
                  We invest a lot of effort in developing the best email
                  solutions for individuals and businesses, with a special
                  emphasis on privacy, reliability, efficiency and security. In
                  addition, we always strive to provide a great experience to
                  all of our users. These are just some of the reasons why
                  professionals prefer BlueMail over other email solutions.
                  BlueMail for Android, iOS, Windows, Mac and Linux work by
                  connecting directly to your email provider. We believe that
                  transmitting emails directly between the BlueMail client and
                  your email provider is the best way to keep your email data
                  secure. Designing BlueMail to work this way was achieved using
                  a unique architectural approach that enables us to provide
                  excellent service while protecting your privacy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={ArchitectureMImg} alt="Instant Push Architecture" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container p-s">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  Optional <span>Secure Push Proxy</span>
                </h2>
                <p>
                  In addition, we offer an optional mobile secure push proxy
                  OAuth supported email accounts on Android, or IMAP and
                  Exchange email accounts on iOS. This proxy serves as a secure
                  pipeline between the email server and your BlueMail mobile
                  app.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={SecurePushImg}
                alt="Optional Secure Push Proxy"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={SecurePushMImg}
                alt="Optional Secure Push Proxy mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid batter-friendly w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  Battery <span>Friendly</span>
                </h2>
                <p>
                  In order to reduce battery consumption, Apple iOS devices
                  limit an app’s background connectivity. While this helps to
                  improve battery performance, it prevents BlueMail for iOS from
                  maintaining a direct connection between your device and email
                  server. Since it’s not possible to keep a direct open
                  connection on your iOS device, BlueMail offers a way to
                  deliver instant push notifications when a new email arrives in
                  your server mailbox. In order to receive this instant push
                  notification, a continuous connection is needed, which we
                  provide through our push proxy. You also have the option to
                  choose other sync modes such as Fetch or Manual, which don’t
                  require the push proxy at any time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5">
              <img src={BatteryFriendlyMImg} alt="Battery Friendly" />
            </div>
          </div>
        </div>
        <div className="container-fluid ephemeral-processing w-707 r">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  Ephemeral <span>Processing</span>
                </h2>
                <p>
                  We use ephemeral processing methods. The push proxy is a
                  secure service that delivers notifications from your email
                  service provider to your device’s platform-specific push
                  service. BlueMail’s push proxy receives emails from the
                  server and transmits them to your device’s push service in no
                  more than 1 to 2 seconds. Push emails are automatically
                  and completely deleted after they’re transmitted and are never
                  stored at any time. The BlueMail push proxy’s sole purpose is
                  to enhance the email experience and deliver instant
                  notifications to your device when a direct connection from the
                  app is not possible. This means that we never store this information.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={EphemeralProcessingMImg} alt="Ephemeral Processing" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container p-s">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  <span>OAuth</span> 2.0
                </h2>
                <p>
                  And for added security, the BlueMail app on your devices uses
                  OAuth 2.0 where applicable and has no access to your account
                  password. Instead, it uses a token provided by the email
                  provider to connect to the email server while keeping your
                  information secure. If you have any question or comment, feel
                  free to keep in touch with our team to learn more about
                  BlueMail’s highly secured solution.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img className="d-992" src={OauthImg} alt="OAuth 2.0" />
              <img
                className="w-991 mt-3 mb-5"
                src={OauthMImg}
                alt="OAuth 2.0 mobile"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default InstantPush

export const query = graphql`
  query InstantPushPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
